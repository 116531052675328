const bulletinColors = () => {
  return {
    color1: [70, 187, 239],
    color2: [64, 187, 239],
    color3: [16, 169, 229],
    color4: [67, 62, 63],
    color5: [52, 172, 215],
    color6: [224, 237, 243],
    color7: [64, 187, 239],
    color8: [225, 225, 226],
    color9: [224, 237, 243],
  };
};

const drawModuleHeader = function (doc, ctx, startY, title) {
  // module title

  let x = 65;
  let y = 63 + startY;
  let width = 125;
  doc.setFillColor(...bulletinColors.color2);
  roundRect(ctx, x, y, width, 8, {}, true, true);
  doc.setFillColor(0xff, 0xff, 0xff);
  doc.setDrawColor(0xff, 0xff, 0xff);
  doc.rect(x, y + 20, width + 10, 10, "FD");

  // module header shapes
  x = 155;
  y = 72.6 + startY;
  width = 47;
  doc.setFillColor(...bulletinColors.color2);
  roundRect(ctx, x, y, width, 7.5, {}, true, true);
  // doc.setFillColor(0xff, 0xff, 0xff);
  // doc.setDrawColor(0xff, 0xff, 0xff);
  // doc.rect(x, y + 9.9, width + 10, 10, "FD");

  x = 139;
  y = 72.6 + startY;
  width = 16;
  doc.setFillColor(...bulletinColors.color6);
  //العدد / 20
  roundRect(ctx, x, y, width, 7.4, {}, true, true);
  // doc.setFillColor(0xff, 0xff, 0xff);
  // doc.setDrawColor(0xff, 0xff, 0xff);
  // doc.rect(x, y + 9.9, width + 10, 10, "FD");

  doc.setLineWidth(0.4);
  doc.setFillColor(...bulletinColors.color6);
  doc.setDrawColor(...bulletinColors.color2);
  //معدل المجال;
  doc.rect(124, 72.3 + startY, 15, 8, "FD");
  //توصيّات المدّرس
  doc.rect(86, 72.3 + startY, 38, 8, "FD");
  //عدد بالقسم

  doc.rect(65, 72.3 + startY, 10, 8, "FD");
  doc.rect(75, 72.3 + startY, 10, 8, "FD");

  doc.line(155, 80.3 + startY, 202.5, 80.3 + startY);

  // header module texts
  if (/[a-zA-Z]/.test(title)) doc.setFont("Amiri", "Bold");
  else doc.setFont("Noto Sans Arabic", "Bold");
  doc.setFontSize(13);
  let text = title;
  doc.setTextColor(0xff, 0xff, 0xff);
  width = doc.getTextWidth(text);
  doc.text(text, 138 - width / 2 - 15, 69 + startY);

  doc.setFont("Amiri", "Bold");
  text = "المادة";
  width = doc.getTextWidth(text);
  doc.text(text, 179, 78 + startY);

  text = "العدد / 20";
  doc.setFontSize(9);
  doc.setTextColor(...bulletinColors.color2);
  width = doc.getTextWidth(text);
  doc.text(text, 141, 78 + startY);

  text = "معدل\nالمجال";
  doc.setFontSize(9);
  doc.setTextColor(...bulletinColors.color2);
  width = doc.getTextWidth(text);
  doc.text(text, 132, 75.2 + startY, "center");

  text = "توصيّات المدّرس";
  doc.setFontSize(11);
  doc.setTextColor(...bulletinColors.color2);
  width = doc.getTextWidth(text);
  doc.text(text, 94, 77.5 + startY);

  text = "أعلى\nعدد بالقسم";
  doc.setFontSize(6);
  doc.setTextColor(...bulletinColors.color2);
  width = doc.getTextWidth(text);
  /**
   * style == 0 ? 80 : 65, 72.3 + startY, 10, 8, "FD");
  doc.rect(style == 0 ? 90 : 75,
   */
  doc.text(text, 80, 75 + startY, "center");

  text = "أدنى\nعدد بالقسم";
  doc.setFontSize(6);
  doc.setTextColor(...bulletinColors.color2);
  width = doc.getTextWidth(text);
  doc.text(text, 70, 75 + startY, "center");
};

const roundRect = function (ctx, x, y, width, height, radius, fill, stroke) {
  const cornerRadius = {
    upperLeft: 0,
    upperRight: 0,
    lowerLeft: 0,
    lowerRight: 0,
  };
  if (typeof stroke == "undefined") {
    stroke = true;
  }
  if (typeof radius === "object") {
    for (const side in radius) {
      cornerRadius[side] = radius[side];
    }
  }

  ctx.lineWidth = 1;
  ctx.strokeStyle = `rgb(${bulletinColors.color1[0]}, ${bulletinColors.color1[1]}, ${bulletinColors.color1[2]})`;

  ctx.beginPath();
  ctx.moveTo(x + cornerRadius.upperLeft, y);
  ctx.lineTo(x + width - cornerRadius.upperRight, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + cornerRadius.upperRight);
  ctx.lineTo(x + width, y + height - cornerRadius.lowerRight);
  ctx.quadraticCurveTo(
    x + width,
    y + height,
    x + width - cornerRadius.lowerRight,
    y + height
  );
  ctx.lineTo(x + cornerRadius.lowerLeft, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - cornerRadius.lowerLeft);
  ctx.lineTo(x, y + cornerRadius.upperLeft);
  ctx.quadraticCurveTo(x, y, x + cornerRadius.upperLeft, y);
  ctx.closePath();
  if (stroke) {
    ctx.stroke();
  }
  if (fill) {
    ctx.fill();
  }
};
const componentToHex = function (c) {
  var hex = c.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
};

const rgbToHex = function (r, g, b) {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
};
const drawTrimesterMoyenneAndCards = function (
  doc,
  ctx,
  startY,
  moy,
  max,
  min,
  pedaMoyenne,
  affichageBulletin,
  stampImage
) {
  const value = 5;
  let yPosition = startY + 68.2;

  let text;
  let width;

  if (affichageBulletin.verySpecific) {
    doc.setFillColor(...bulletinColors.color5);
    doc.setDrawColor(...bulletinColors.color5);
    roundRect(
      ctx,
      45 - value * 2,
      yPosition,
      33.5 - value,
      8.2,
      {},
      true,
      false
    );

    doc.setFont("Amiri", "Bold");
    doc.setFontSize(11);
    text = affichageBulletin.verySpecificName;
    doc.setTextColor(0xff, 0xff, 0xff);
    width = doc.getTextWidth(text);
    doc.text(text, 65.5 - value * 3 - width / 2, yPosition + 5.3);

    doc.setLineWidth(0.2);
    doc.setFillColor(...bulletinColors.color6);
    doc.setDrawColor(...bulletinColors.color2);

    roundRect(ctx, 6, yPosition, 38 - value * 2, 7.8, {}, true, true);
    roundRect(ctx, 6, yPosition, 19 - value, 7.8, {}, true, true);

    doc.setFont("Amiri", "Bold");
    doc.setFontSize(7);
    doc.setTextColor(...bulletinColors.color2);
    doc.text(
      "أعلى\nمعدل بالقسم",
      39 - (value + value / 2),
      yPosition + 3.3,
      "right"
    );
    doc.text("أدنى\nمعدل بالقسم", 20.2 - value / 2, yPosition + 3.3, "right");

    // moyenne rect
    doc.setFillColor(...bulletinColors.color6);
    doc.setDrawColor(...bulletinColors.color2);
    doc.setLineWidth(0.15);
    doc.rect(45 - value * 2, yPosition + 9.3, 33.5 - value, 16.4, "FD");

    doc.setFillColor(...bulletinColors.color8);
    doc.rect(6, yPosition + 9.3, 19 - value, 16.4, "FD");
    doc.rect(25 - value, yPosition + 9.3, 19 - value, 16.4, "FD");

    // moyenne text
    doc.setTextColor(...bulletinColors.color4);
    doc.setFontSize(16);
    text = String(moy);
    width = doc.getTextWidth(text);
    doc.text(text, 63 - width / 2 - (value * 2 + value / 2), yPosition + 18.3);

    doc.setFontSize(13);
    text = String(max);
    width = doc.getTextWidth(text);
    doc.text(text, 34.75 - width / 2 - (value + value / 2), yPosition + 18.3);

    text = String(min);
    width = doc.getTextWidth(text);
    doc.text(text, 15.25 - width / 2 - value / 2, yPosition + 18.3);

    yPosition += 27; //99.2
  }

  if (affichageBulletin.pedagogic) {
    doc.setFillColor(...bulletinColors.color5);
    doc.setDrawColor(...bulletinColors.color2);
    roundRect(ctx, 6, yPosition, 73 - value * 3, 8.2, {}, true, false);

    doc.setFillColor(...bulletinColors.color6);
    doc.setDrawColor(...bulletinColors.color2);
    doc.setLineWidth(0.05);

    doc.rect(6, yPosition + 9, 73 - value * 3, 8.2, "FD");

    doc.setFontSize(10);
    text = affichageBulletin.pedagogicName;
    width = doc.getTextWidth(text);
    doc.setTextColor(0xff, 0xff, 0xff);
    doc.text(text, 45 - width / 2 - value * 2, yPosition + 5.3);

    doc.setFontSize(11);
    text = pedaMoyenne ? String(pedaMoyenne) : "--";
    width = doc.getTextWidth(text);
    doc.setTextColor(...bulletinColors.color4);
    doc.text(text, 45 - width / 2 - value * 2, yPosition + 14.3);

    yPosition += 20; //110
  }

  if (affichageBulletin.personalized) {
    doc.setFillColor(...bulletinColors.color5);
    doc.setDrawColor(...bulletinColors.color2);
    roundRect(ctx, 6, yPosition, 73 - value * 3, 8.2, {}, true, false);

    doc.setFillColor(...bulletinColors.color6);
    doc.setDrawColor(...bulletinColors.color2);
    doc.setLineWidth(0.05);
    doc.rect(6, yPosition + 9, 73 - value * 3, 8.2, "FD");

    doc.setFontSize(10);
    text = affichageBulletin.personalizedName;
    width = doc.getTextWidth(text);
    doc.setTextColor(0xff, 0xff, 0xff);
    doc.text(text, 45 - width / 2 - value * 2, yPosition + 5.3);

    let perMoyenne = "--";
    if (pedaMoyenne && moy && pedaMoyenne != "--" && moy != "--") {
      const totalCoef =
        affichageBulletin.pedagogicCoef + affichageBulletin.verySpecificCoef;
      perMoyenne = String(
        (
          (Number(moy) * affichageBulletin.verySpecificCoef +
            Number(pedaMoyenne) * affichageBulletin.pedagogicCoef) /
          totalCoef
        ).toFixed(2)
      );
    }
    doc.setFontSize(11);
    width = doc.getTextWidth(perMoyenne);
    doc.setTextColor(...bulletinColors.color4);
    doc.text(perMoyenne, 45 - width / 2 - value * 2, yPosition + 14.5);

    yPosition += 20; // 121
  }
  //الشّهادة
  if (affichageBulletin.certif) {
    doc.setFillColor(...bulletinColors.color6);
    doc.setDrawColor(...bulletinColors.color2);

    yPosition -= 36;

    doc.rect(6, yPosition + 42, 72.5 - value * 3, 20);
    doc.rect(40.75 - value * 3, yPosition + 38, 36.75, 8, "FD");

    doc.setFontSize(13);

    doc.setTextColor(...bulletinColors.color2);

    text = "الشّهادة";
    doc.text(text, 55 - value * 3, yPosition + 43);

    doc.setTextColor(67, 62, 63);

    doc.text(
      getCertificationNameByMoyenne(moy),

      40 - value * 3,
      yPosition + 55
    );
    yPosition += 65;
  }
  //مدير)ة( المدرسة"
  doc.setLineWidth(0.15);

  doc.setFillColor(...bulletinColors.color6);
  doc.setDrawColor(...bulletinColors.color2);

  yPosition -= 36;

  doc.rect(6, yPosition + 42, 72.5 - value * 3, 55);
  doc.rect(40.75 - value * 3, yPosition + 38, 36.75, 8, "FD");

  doc.rect(6, yPosition + 106, 72.5 - value * 3, 30);
  doc.rect(40.75 - value * 3, yPosition + 102, 36.75, 8, "FD");

  doc.setFontSize(13);

  doc.setTextColor(...bulletinColors.color2);

  text = "مدير)ة( المدرسة";
  doc.text(text, 45 - value * 3, yPosition + 43);

  doc.setTextColor(67, 62, 63);
  doc.text(
    schoolDetails.headMaster + "\n" + moment(bulletinDate).format("YYYY/MM/DD"),
    30 - value * 3,
    yPosition + 51
  );

  doc.setTextColor(...bulletinColors.color2);
  doc.setFontSize(16);

  text = "إمضاء الولي";
  doc.text(text, 48 - value * 3, yPosition + 107);

  //stamp
  if (stampImage && stampImage.src) {
    try {
      doc.addImage(stampImage, "PNG", 10, yPosition + 61, 30, 30);
    } catch (e) {}
  }
  //end stamp
};
const drawModuleFooter = function (doc, ctx, startY, avg) {
  let x = 158;
  let y = startY;
  let width = 35.5;
  doc.setFillColor(...bulletinColors.color3);
  doc.setDrawColor(...bulletinColors.color3);
  doc.rect(x, y, width + 10, 8.5, "FD");

  x = 111;
  y = startY + 0.4;
  width = 45.5;
  doc.setFillColor(255, 255, 255);

  roundRect(ctx, x, y, width, 7.8, {}, true, true);

  // footer text (moyenne)
  doc.setFont("Noto Sans Arabic", "Bold");
  doc.setFontSize(13);
  let text = "معدل المجال";
  doc.setTextColor(0xff, 0xff, 0xff);
  doc.text(text, 169, startY + 5.5);

  doc.setFont("Amiri", "Bold");
  doc.setFontSize(14);
  text = String(avg);
  doc.setTextColor(...bulletinColors.color4);
  width = doc.getTextWidth(text);
  doc.text(text, 134.75 - width / 2, startY + 5.5);
};

const drawBulletin = async function (
  doc,
  ctx,
  student,
  bulletinData,
  affichageBulletin,
  stampImage,
  config
) {
  const { selectedTrimester, schoolDetails, schoolarYearName, selectedLevel } =
    config;
  const record = student;

  const pdf_width = doc.internal.pageSize.width;
  let width = 0;
  let width2 = 0;

  // ending header line
  doc.setDrawColor(...bulletinColors.color5);
  doc.setLineWidth(0.8);
  doc.line(0, 40 - 12, pdf_width, 40 - 12);

  // trimester rounded rect title
  doc.setDrawColor(...bulletinColors.color5);
  doc.setLineWidth(0.4);
  doc.setFillColor(255, 255, 255);
  doc.roundedRect(pdf_width / 2 - 25, 34 - 12, 50, 12, 4, 4, "FD");

  // trimester text
  doc.setFontSize(16);
  doc.setFont("Amiri", "normal");
  doc.setTextColor(...bulletinColors.color5);
  let title = "";
  switch (selectedTrimester) {
    case "1":
      title = "الثلاثي الأول";
      break;
    case "2":
      title = "الثلاثي الثاني";
      break;
    case "3":
      title = "الثلاثي الثالث";
      break;
  }
  doc.setFont("Noto Sans Arabic", "Bold");
  width = doc.getTextWidth(title);
  doc.text(title, pdf_width / 2 - width / 2, 41.2 - 12);

  const className = bulletinData.classroomName;

  let studentLabel = record.gender === "male" ? " : التلميذ" : " : التلميذة";

  // student name
  doc.setFont("Amiri", "normal");
  doc.setFontSize(12);

  doc.setTextColor(0, 0, 0);

  text = schoolDetails.ArabicName
    ? " المدرسة الابتدائيّة " + schoolDetails.ArabicName
    : "............................... : المدرسة الابتدائيّة";
  width = doc.getTextWidth(text);
  doc.text(text, pdf_width - width - 12, 12);
  text = ` ${schoolarYearName}  : السنة الدراسيّة`;
  width = doc.getTextWidth(text);
  doc.text(text, 10, 12);

  let text = studentLabel;
  width = doc.getTextWidth(text);
  doc.text(text, pdf_width - width - 12, 17.5);

  text = student.fullName;
  doc.setFont("Amiri", "normal");
  doc.setFontSize(13);
  width2 = doc.getTextWidth(text);
  doc.text(text, pdf_width - width - width2 - 12, 17.5);
  doc.setFont("Amiri", "normal");
  doc.setFontSize(12);
  // student classroom
  text = " : القسم";
  doc.setTextColor(0, 0, 0);
  width = doc.getTextWidth(text);
  width2 = doc.getTextWidth(className);
  doc.text(text, 13 + width2, 17.5);

  doc.text(className, 12, 17.5);
  // number of students in classroom
  if (affichageBulletin.numberStudentInsc) {
    text = String(
      Array.isArray(bulletinData.students)
        ? bulletinData.students.length
        : bulletinData.students
    );
    width2 = doc.getTextWidth(text);
    doc.text(text, 12, 23);
    text = " : عدد التلاميذ المرسّمين";
    width = doc.getTextWidth(text);
    doc.text(text, 12 + width2, 23);
  }

  // identUnique
  if (affichageBulletin.identUnique && student.uniqueId) {
    text = " : المعرف الوحيد";
    width = doc.getTextWidth(text);
    doc.text(text, pdf_width - width - 12, 23);
    text = String(student.uniqueId);
    width2 = doc.getTextWidth(text);
    doc.text(text, pdf_width - width2 - width - 12, 23);
  }
  const cellToDelete = {};
  let topMargin = -21;
  let tableSize = 9;
  switch (selectedLevel) {
    case 1:
      tableSize = 11.5;
      break;
    case 2:
      tableSize = 11.5;
      break;
    case 3:
      tableSize = 10.5;
      break;
    case 4:
      tableSize = 9.5;
      break;
    default:
      break;
  }

  student.modules.forEach((m) => {
    //try {
    let arr = [];
    let array = [];

    drawModuleHeader(doc, ctx, topMargin, m.name);
    topMargin += 17.2;

    m.subjects.forEach((c) => {
      let subFormattedName = c.name;
      if (c.name.length > 20 && c.name.indexOf(" و ") != -1) {
        let indexOfFirst = c.name.indexOf(" و ");
        subFormattedName =
          c.name.slice(0, indexOfFirst) + "\n" + c.name.slice(indexOfFirst);
      }
      const spanNumber = c.subSubjectMarks ? c.subSubjectMarks.length : 1;
      arr[8] = {
        content: subFormattedName,
        rowSpan: spanNumber,
        colSpan: spanNumber == 1 ? 3 : 1,
        styles: {
          halign: "right",
          valign: "middle",

          fillColor: rgbToHex(...bulletinColors.color7),
          fontSize: tableSize,
          textColor: "#ffffff",
          lineColor: [229, 229, 229],
        },
      };
      arr[5] = {
        content: c.mark,
        rowSpan: spanNumber,
        styles: {
          halign: "center",
          valign: "center",
          fontSize: 10,
          lineColor: [...bulletinColors.color5],
        },
      };

      arr[4] = cellToDelete;
      arr[3] = cellToDelete;
      arr[2] = cellToDelete;

      //best mark
      arr[1] = {
        content: c.max,
        rowSpan: spanNumber,
        styles: {
          halign: "center",
          valign: "center",
          fontSize: 9,
          cellPadding: {
            right: 0,
            left: 0,
            top: 0,
            bottom: 1.25,
          },
          lineColor: [...bulletinColors.color5],
        },
      };

      //lowest mark
      arr[0] = {
        content: c.min,
        rowSpan: spanNumber,
        styles: {
          halign: "center",
          valign: "center",
          fontSize: 9,
          cellPadding: {
            right: 0,
            left: 0,
            top: 0,
            bottom: 1.25,
          },
          lineColor: [...bulletinColors.color5],
        },
      };
      if (c.subSubjectMarks)
        c.subSubjectMarks.forEach((subs) => {
          try {
            let subSStyle = {
              halign: "center",
              valign: "middle",
              fontSize: 8,
              fillColor: rgbToHex(...bulletinColors.color9),
              textColor: rgbToHex(...bulletinColors.color2),
              lineColor: bulletinColors.color2,
            };
            arr[6] = {
              content: subs.mark,
              styles: subSStyle,
            };
            subSStyle.halign = "right";
            arr[7] = {
              content: `${subs.name} \n (/${subs.maxMark})`,
              styles: subSStyle,
            };
            array.push(arr);
            arr = [];
          } catch (error) {
            console.error("error sub", error);
          }
        });
      else array.push(arr);
      arr = [];
    });

    //end shifting

    array[0][2] = {
      content: "",
      rowSpan: array.length,
      styles: {
        lineColor: [...bulletinColors.color5],
      },
    };
    array[0][3] = {
      content: m.appreciation.trim(),
      rowSpan: array.length,
      styles: {
        halign: "center",
        valign: "top",
        fontSize: m.appreciation.length > 15 ? 10 : 12,
        lineColor: [...bulletinColors.color5],
      },
    };

    array[0][4] = {
      content: m.moyenne,
      rowSpan: array.length,
      styles: {
        halign: "center",
        fontSize: 12,
        valign: "center",
        lineColor: [...bulletinColors.color5],
      },
    };

    for (let row = 0; row < array.length; row++) {
      array[row] = array[row].filter((cell) => cell !== cellToDelete);
    }
    doc.autoTable({
      startY: 64 + topMargin,
      theme: "grid",
      styles: {
        font: "Amiri",
        halign: "center",
        fontStyle: "Bold",
      },
      cellPadding: 0,
      body: array,
      margin: { left: 65, bottom: 0 },
      didDrawPage: function (data) {
        // Reseting top margin. The change will be reflected only after print the first page.
        data.settings.margin.top = 10;
      },
      columnStyles: {
        0: { cellWidth: 10 },
        1: { cellWidth: 10 },
        2: { cellWidth: 1 },
        3: { cellWidth: 38 },
        4: { cellWidth: 15 },
        5: { cellWidth: 16 },
        6: { cellWidth: 8 },
        7: { cellWidth: 18 },
        8: { cellWidth: 23 },
      },
    });

    topMargin = doc.lastAutoTable.finalY - 61.4 + 2;
    /*} catch (error) {
        console.error("error.stack");

        console.trace(error);
      }*/
  });

  drawTrimesterMoyenneAndCards(
    doc,
    ctx,
    -27,
    student.moyenne,
    bulletinData.maxMoyenne,
    bulletinData.minMoyenne,
    student.pedaMoyenne,
    affichageBulletin,
    stampImage
  );
};

exports = {
  drawBulletin,
};
