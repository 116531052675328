/*eslint-disable */
//ANCHOR class/month filter to this view: DONE

import apiClient from "@/services/axios";
import { mapState } from "vuex";
import JsPDF from "jspdf";
import "jspdf-autotable";
import { notification, Modal } from "ant-design-vue";
import moment from "moment";
import { drawBulletin } from "./BulletinHelpers/spec";

export default {
  computed: {
    ...mapState(["settings", "user"]),
    bulletinType: function () {
      switch (this.type) {
        case "MP":
          return "bulletin";
        case "MT":
          return "bulletinSpec";
        case "MS":
          return "bulletinVerySpec";
      }
    },
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    subModuleList: {
      type: Array,
      required: true,
    },
  },
  destroyed() {
    window.removeEventListener("beforeunload", this.confirmLeaveReload);
  },
  async created() {
    // DONE
    window.addEventListener("beforeunload", this.confirmLeaveReload);
    this.tableLoading = true;
    let project = {
      _id: 1,
      name: 1,
      level: 1,
    };
    project[this.bulletinType] = 1;
    await apiClient
      .post("/classrooms/filter", {
        query: {
          status: "active",
        },
        aggregation: {
          $project: project,
        },
      })
      .then((res) => (this.classes = res.data));
    apiClient
      .get(`/certification/${this.type == "MP" ? "peda" : "spec"}`)
      .then((res) => {
        this.certifParams = res.data;
      })
      .catch((e) => {
        console.log(e);
      });
    this.tableLoading = false;
    this.filtredTable = this.activeData;

    this.schoolDetails = this.user.building.filter(
      (el) => el.dbName == this.settings.activeBuilding
    )[0];

    let { start, end } = this.settings.schoolarYears.find(
      (el) => el._id == this.settings.activeSchoolarYear
    );

    start = new Date(start).getFullYear();
    end = new Date(end).getFullYear();

    this.schoolarYearName = end + " / " + start;

    this.schoolType = this.user.building.filter(
      (el) => el.dbName == this.settings.activeBuilding
    )[0].type;
    if (!this.schoolType)
      notification.warning({
        message: this.$t("bulletin.typeIntrouvable"),
        description: this.$t("bulletin.saisirSchoolType"),
      });

    // apiClient
    //   .get("/mark/classroomMarksStats/" + this.selectedTrimester + "/normal")
    //   .then(({ data }) => {
    //     this.classroomMarksStats = data;
    //   });
  },
  data() {
    // DONE
    return {
      visibleBulletinDate: false,
      bulletinDate: new Date(),
      updatedChanges: true,
      printLoading: false,
      syncLoading: false,
      syncSubjectLoading: false,
      visibleClassnameList: false,
      visibleSubjectList: false,
      schoolType: null,
      filtredTable: [],
      classroomMarksStats: {},
      selectedSubject: this.$t("scolarite.listeMatieres"),
      selectedSubjectName: "",
      bulletins: { students: [] },
      selectedClassName: "",
      markData: [],
      originalMarkData: [],
      activeModule: null,
      bulletinData: [],
      certifParams: [],
      moduleLoading: false,
      selectedTrimester: "1",
      selectedClasse: null,
      tableLoading: false,
      saveLoading: false,
      activeData: [],
      markRefs: [],
      filters: {
        classe: false,
        classe_val: null,
        month: false,
        month_val: null,
      },
      rowData: [],
      data1: null,
      classes: [],
      editable: false,
      searchText: "",
      searchInput: null,
      searchedColumn: "",
      columns: [],
      subsubjectData: [],
      listModules: [],
      selectedLevel: null,
      bulletinColors: {
        color1: [70, 187, 239],
        color2: [64, 187, 239],
        color3: [16, 169, 229],
        color4: [67, 62, 63],
        color5: [52, 172, 215],
        color6: [224, 237, 243],
        color7: [64, 187, 239],
        color8: [225, 225, 226],
        color9: [224, 237, 243],
      },
      schoolDetails: {},
      schoolarYearName: "",
    };
  },
  // DONE
  beforeRouteLeave(to, from, next) {
    const _this = this;

    if (this.updatedChanges) next();
    else
      this.$confirm({
        title: this.$t("all.unsavedchanges"),
        content: this.$t("all.unsavedchangesContent"),
        okText: this.$t("all.oui"),
        okType: "danger",
        cancelText: this.$t("action.annuler"),
        onOk() {
          _this.updatedChanges = true;
          next();
        },
        onCancel() {
          next(false);
        },
      });
  },
  methods: {
    moment,
    drawBulletin,
    getSumMaxMArks(subsubjectData) {
      // DONE
      let sumMax = 0;
      subsubjectData.forEach((sub) => {
        sumMax += sub.maxMark;
      });
      return sumMax;
    },
    confirmLeaveReload(event) {
      // DONE
      if (!this.updatedChanges) {
        event.returnValue = this.$t("all.unsavedchangesContent");
      }
    },
    confirmeBulletinValidation() {
      // DONE
      const content = this.isValidatedBulletin()
        ? this.$t("bulletin.hideBulletin")
        : this.$t("bulletin.validateBulletin");
      this.$confirm({
        title: this.$t("all.sureTo"),
        content: (h) => <div style="color:red"> {content} </div>,
        onOk: () => {
          //console.log("OK");
          this.validateBulletin();
        },
        onCancel: () => {
          //console.log("Cancel");
        },
        class: "test",
      });
    },
    isValidatedBulletin() {
      // DONE
      if (this.selectedClasse) {
        const classsroom = this.classes.find(
          (item) => item._id === this.selectedClasse
        );
        const bulletin = classsroom
          ? classsroom[this.bulletinType]
          : { sem1: false, sem2: false, sem3: false };
        if (this.selectedTrimester == 1 && bulletin.sem1 == true) return true;
        else if (this.selectedTrimester == 2 && bulletin.sem2 == true)
          return true;
        else if (this.selectedTrimester == 3 && bulletin.sem3 == true)
          return true;
      }
      return false;
    },
    validateBulletin() {
      // DONE
      const isValidated = this.isValidatedBulletin();
      const classsroom = this.classes.find(
        (item) => item._id === this.selectedClasse
      );
      const bulletin = classsroom
        ? classsroom[this.bulletinType]
        : { sem1: false, sem2: false, sem3: false };
      if (this.selectedTrimester == 1) bulletin.sem1 = !bulletin.sem1;
      if (this.selectedTrimester == 2) bulletin.sem2 = !bulletin.sem2;
      if (this.selectedTrimester == 3) bulletin.sem3 = !bulletin.sem3;
      apiClient
        .patch("/classrooms/" + this.selectedClasse, {
          data: {
            bulletinVerySpec: bulletin,
          },
          bulletin: {
            classroom: this.selectedClasse,
            trimester: this.selectedTrimester,
            avaliable: !isValidated,
            type: this.bulletinType,
          },
        })
        .then(() => {
          this.classes = this.classes.map((item) => {
            if (item._id === this.selectedClasse)
              item[this.bulletinType] = bulletin;
            return item;
          });

          if (!isValidated)
            this.$message.success(
              this.$t("bulletin.validated", { name: this.selectedClassName })
            );
          else
            this.$message.success(
              this.$t("bulletin.unvalidated", { name: this.selectedClassName })
            );
        });
    },
    getCertificationNameByMoyenne(moyenne) {
      // DONE
      for (const cert of this.certifParams)
        if (
          Number(moyenne) >= Number(cert.min) &&
          Number(moyenne) <= Number(cert.max)
        ) {
          return cert.name;
        }

      return "";
    },
    async sleep(timeout) {
      await new Promise((r) => setTimeout(r, timeout));
    },
    async chooseBulletinDate() {
      // GOOD
      this.visibleBulletinDate = true;
      return new Promise(async (res) => {
        while (this.visibleBulletinDate) {
          await this.sleep(500);
        }
        res();
      });
    },
    async printAllBulletinSpec() {
      this.printLoading = true;
      const doc = new JsPDF();
      const ctx = doc.context2d;
      let bulletinData;
      let firstPage = true;
      let affichageBulletin;

      await apiClient
        .post("/affichageBulletin/filter", {
          query: {
            level: this.selectedLevel,
            type: this.bulletinType,
          },
        })
        .then((res) => {
          if (res.data[0]) {
            affichageBulletin = res.data[0];
          } else {
            affichageBulletin = {
              verySpecific: true,
              pedagogic: false,
              personalized: false,
              certif: false,
              verySpecificName: "معدل",
            };
          }
        })
        .catch((e) => {
          this.$message.error(this.$t("error.erreur"));
        });

      await apiClient
        .get(
          "/bulletin/v2/mark/all/" +
            this.selectedClasse +
            "/" +
            this.selectedTrimester
        )
        .then(({ data }) => {
          bulletinData = data;
        });

      //stamp
      let stamp = this.settings.base_url + "/" + this.schoolDetails.stamp;
      const stampImage = new Image(30);
      if (stamp) stampImage.src = stamp;
      if (stamp) {
        try {
          await this.waitLoadingImage(stampImage);
        } catch (e) {}
      }
      //end stamp

      await this.chooseBulletinDate();

      bulletinData.students.forEach((student) => {
        if (!firstPage) {
          doc.addPage();
        }
        this.drawBulletin(
          doc,
          ctx,
          student,
          bulletinData,
          affichageBulletin,
          stampImage,
          (config = {
            selectedTrimester: this.selectedTrimester,
            schoolDetails: this.schoolDetails,
            schoolarYearName: this.schoolarYearName,
            selectedLevel: this.selectedLevel,
          })
        );
        firstPage = false;
      });
      this.printLoading = false;

      const className = this.selectedClassName;
      doc.save(`Bulletin ${className}.pdf`);
    },
    async getModules(level) {
      await apiClient
        .post("/bulletin/v2/module/filter/marks", {
          status: "active",
          level: level,
          trimester: this.selectedTrimester,
          type: this.type,
        })
        .then((res) => {
          this.subjectsLoading = true;

          if (res.data.length == 0)
            return this.$message.warning(this.$t("error.aucModule"));
          res.data.forEach((moduleItem) => {
            moduleItem.subjects = moduleItem.subjects.map((subject) => {
              const subjectsInfo = moduleItem.subjectsInfo;
              const subsubjects = subjectsInfo[subject._id];
              subject.moduleName = moduleItem.name;
              if (subsubjects && subsubjects.sousModule)
                subject.subModule = this.subModuleList.find(
                  (sm) => String(sm._id) == String(subsubjects.sousModule)
                );
              return subject;
            });
            this.listModules.push(moduleItem);
          });
          this.subjectsLoading = false;
        })
        .catch((e) => {
          console.log(e);
          this.$message.error(this.$t("error.erreur"));
          this.moduleLoading = false;
        });
    },
    tableChanged(pagination, filters, sorter, extra) {
      this.filtredTable = extra.currentDataSource;
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },

    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },

    async saveAll() {
      this.saveLoading = true;

      // check if subsubject marks are completed
      if (this.divided) {
        const subsubjects = this.subsubjectData.map((s) => String(s._id));
        for (const student of this.activeData) {
          const marks = this.markData.filter((m) => {
            return (
              m.student == student._id &&
              m.divided &&
              subsubjects.includes(m.subsubject)
            );
          });

          if (marks.length != 0 && marks.length != subsubjects.length) {
            this.saveLoading = false;
            return this.$message.warning(
              this.$t("warning.missedSubsubjects", { name: student.fullName })
            );
          }
        }
      }

      // check if marks are correct
      let thereError = false;
      for (const mark of this.markData) {
        if (!mark.divided) {
          if (isNaN(Number(mark.mark)) || mark.mark > 20 || mark.mark < 0) {
            thereError = true;
            break;
          }
        } else if (mark.divided) {
          const subsubject = this.subsubjectData.find(
            (s) => s._id == mark.subsubject
          );
          if (subsubject)
            if (
              isNaN(Number(mark.mark)) ||
              mark.mark > subsubject.maxMark ||
              mark.mark < 0
            ) {
              thereError = true;
              break;
            }
        }
      }

      if (thereError) {
        this.$message.warning(this.$t("warning.notCorrectMarks"));
        this.saveLoading = false;
        return;
      }

      // saving
      const toAddData = [];

      for (const mark of this.markData) {
        const originalMark = this.originalMarkData.find(
          (m) =>
            m.student == mark.student &&
            ((!m.divided && m.subject == mark.subject) ||
              (m.divided && m.subsubject == mark.subsubject))
        );
        if (originalMark) {
          if (Number(mark.mark) != Number(originalMark.mark))
            await apiClient
              .patch("/bulletin/v2/mark/" + originalMark._id, {
                data: {
                  mark: mark.mark,
                },
              })
              .then()
              .catch((err) => console.log(err));
        } else {
          const obj = {
            divided: mark.divided,
            student: mark.student,
            mark: mark.mark,
            trimester: this.selectedTrimester,
            classroom: this.selectedClasse,
            subject: mark.subject,
            type: "one",
          };
          if (mark.divided) obj.subsubject = mark.subsubject;
          toAddData.push(obj);
        }
      }

      await apiClient
        .put("/bulletin/v2/mark/many", {
          data: toAddData,
        })
        .then()
        .catch((err) => console.log(err));

      for (const originalMark of this.originalMarkData) {
        const mark = this.markData.find(
          (m) =>
            m.student == originalMark.student &&
            ((!m.divided && m.subject == originalMark.subject) ||
              (m.divided && m.subsubject == originalMark.subsubject))
        );
        if (!mark)
          await apiClient
            .delete("/bulletin/v2/mark/" + originalMark._id)
            .then()
            .catch((err) => console.log(err));
      }

      await this.updateMarks();

      this.saveLoading = false;
      this.updatedChanges = true;
      this.$message.success(this.$t("success.noteToutesEnregistre"));
    },

    deleteMark(id, studentId, divided) {
      this.markData = this.markData.filter(
        (m) =>
          !(
            m.student == studentId &&
            m.divided == divided &&
            (m.subsubject == id || m.subject == id)
          )
      );
      this.updatedChanges = false;
    },

    handleMarkChange(newMark, subjectId, subSubjectId, studentId, divided) {
      const mark = this.markData.find(
        (m) =>
          m.student == studentId &&
          ((divided && m.subsubject == subSubjectId) ||
            (!divided && m.subject == subjectId))
      );
      if (mark) mark.mark = Number(newMark);
      else {
        const obj = {
          student: studentId,
          mark: Number(newMark),
          divided: divided,
          subject: subjectId,
        };
        if (divided) obj.subsubject = subSubjectId;

        this.markData.push(obj);
      }

      this.updatedChanges = false;
    },

    getMarkValue(id, studentId, divided) {
      const mark = this.markData.find(
        (m) =>
          m.student == studentId &&
          m.divided == divided &&
          (m.subsubject == id || m.subject == id)
      );
      if (mark) return mark.mark;
      return null;
    },

    getTotalSubsubjects(record) {
      try {
        const subsubjects = this.subsubjectData.map((s) => String(s._id));
        const marks = this.markData.filter((m) => {
          return (
            m.student == record._id &&
            m.divided &&
            subsubjects.includes(m.subsubject)
          );
        });
        let total = 0;
        marks.forEach((mark) => {
          total += mark.mark;
        });
        let sumMax = this.getSumMaxMArks(this.subsubjectData);
        return total ? ((total * 20) / sumMax).toFixed(2) : "";
      } catch (error) {
        console.log(error);
      }
    },

    async handleSubjectChange(val) {
      this.columns = [
        {
          title: this.$t("bulletin.nom"),
          dataIndex: "fullName",
          key: "fullName",
          scopedSlots: {
            customRender: "fullName",
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          onFilter: (value, record) =>
            record.fullName
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              }, 0);
            }
          },
        },
      ];

      this.subsubjectData = [];

      const subsubjects = this.activeModule.subjectsInfo[this.selectedSubject];
      if (subsubjects && subsubjects.subsubjectsMark) {
        this.divided = true;
        for (const sub of Object.keys(subsubjects.subsubjectsMark)) {
          const s = this.activeModule.subsubjects.find(
            (s) => String(s._id) == sub
          );
          this.columns.push({
            title: s.name,
            dataIndex: s._id,
            scopedSlots: { customRender: s._id },
          });
          this.subsubjectData.push({
            name: s.name,
            _id: s._id,
            divided: true,
            maxMark: subsubjects.subsubjectsMark[sub],
          });
        }
      } else {
        this.divided = false;
      }

      this.columns.push({
        title: "Note",
        dataIndex: "note",
        scopedSlots: { customRender: "note" },
      });
      this.updateMarks();
    },

    async updateMarks() {
      this.tableLoading = true;
      await apiClient
        .post("/bulletin/v2/mark/filter", {
          query: {
            classroom: this.selectedClasse,
            trimester: this.selectedTrimester,
            type: {
              $in: ["one"],
            },
          },
        })
        .then(({ data }) => {
          this.markData = [];
          this.originalMarkData = [];
          this.originalMarkData = data;
          data.map((mark) => {
            this.markData.push({
              student: mark.student,
              subject: mark.subject,
              subsubject: mark.subsubject,
              mark: mark.mark,
              divided: mark.divided,
            });
          });
        })
        .finally(() => (this.tableLoading = false));
    },

    async handleClassChange(on, value) {
      this.markRefs = [];
      this.selectedSubject = "";
      this.moduleLoading = true;
      this.selectedClasse = value;
      this.selectedClassName = this.classes.find(
        (item) => item._id === value
      ).name;
      this.selectedLevel = this.classes.find(
        (item) => item._id === value
      ).level;
      let match = {};
      match[`schoolarYearsHistory.${this.settings.activeSchoolarYear}`] = value;
      await apiClient
        .post("/students/filter", {
          query: { status: "active" },
          aggregation: [
            {
              $match: match,
            },
            {
              $set: {
                classRoom: {
                  $convert: {
                    input: `$schoolarYearsHistory.${this.settings.activeSchoolarYear}`,
                    to: "objectId",
                    onError: null,
                    onNull: null,
                  },
                },
              },
            },
            {
              $lookup: {
                from: "classrooms",
                localField: "classRoom",
                foreignField: "_id",
                as: "classroom",
              },
            },
            {
              $project: {
                _id: 1,
                firstName: 1,
                lastName: 1,
                firstNameLower: { $toLower: "$firstName" },
                gender: 1,
                classroom: {
                  _id: 1,
                  name: 1,
                },
              },
            },
            {
              $sort: {
                firstNameLower: 1,
              },
            },
          ],
        })
        .then(({ data }) => {
          this.rowData = [];
          data.map((elem) => {
            try {
              this.rowData.push({
                _id: elem._id,
                key: elem._id,
                fullName: elem.firstName + " " + elem.lastName,
                classroom: elem.classroom[0].name,
                classroom_id: elem.classroom[0]._id,
                gender: elem.gender,
              });
            } catch {}
          });
          this.activeData = this.rowData;
          this.tableLoading = false;
          this.filtredTable = this.activeData;
        });
      //get modules
      this.getModules(this.selectedLevel);
      this.updateMarks();
    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
  },
};
