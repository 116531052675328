<template>
  <div class="card col-xl-12 col-lg-12">
    <div class="card-header card-header-flex flex-column">
      <a-tabs
        class="kit-tabs"
        :default-active-key="activeKey"
        @change="callback"
      >
        <a-tab-pane :tab="this.$t('menu.notesParEleve')" key="1" />
        <a-tab-pane :tab="this.$t('menu.notesModule')" key="2" />
        <a-tab-pane :tab="this.$t('menu.notesModuleComplet')" key="3" />
      </a-tabs>
    </div>
    <div class="card-body" v-if="loading">
      <span class="indicator-progress">
        Please wait...
        <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
      </span>
    </div>
    <div class="card-body" v-else>
      <component
        v-bind:is="currentTabComponent"
        :key="type + loadKey"
        :type="type"
        :subModuleList="subModuleList"
      ></component>
    </div>
  </div>
</template>
<script>
/*eslint-disable*/
import appreciationAdmin from "./appreciationAdmin";
import subjectMark from "./subjectMarks";
import moduleMark from "./moduleMarks";
import studentMarks from "./studentMarks";
import apiClient from "@/services/axios";
export default {
  name: "Bulletin",
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  components: {
    subjectMark,
    appreciationAdmin,
    moduleMark,
    studentMarks,
  },
  data() {
    return {
      activeKey: "1",
      loadKey: 0,
      selectedMarkBy: "",
      subModuleList: {},
      loading: true,
    };
  },
  computed: {
    currentTabComponent: function () {
      switch (this.activeKey) {
        case "1":
          return "studentMarks";
        case "2":
          return "subjectMark";
        case "3":
          return "moduleMark";
      }
    },
  },
  async created() {
    this.loading = true;
    await this.getSubModuleList();
    this.loading = false;
  },
  methods: {
    getSubModuleList: async function () {
      try {
        const { data } = await apiClient.post("/bulletin/v2/sub-module/list", {
          status: "active",
        });
        this.subModuleList = data;
      } catch (error) {
        this.$message.error(this.$t("error.erreur"));
      }
    },
    callback: function (key) {
      this.activeKey = key;
      this.loadKey += 1;
    },
  },
};
</script>

<style scoped>
::v-deep .ant-tabs-nav-wrap {
  overflow: hidden !important;
}
</style>
